import request from "@/utils/request";

export function requestPost(url, data) {
  console.log(url, data)
  return request({
    url: url,
    method: 'post',
    data
  })
}

export function requestGet(url, data) {
  return request({
    url: url,
    method: 'get',
    params: data
  })
}