import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      return config
    } else {
      return config
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code !== '200') {
      Message({
        message: res.msg || "Something's wrong.",
        type: 'error',
        duration: 5000,
      })
      return Promise.reject(new Error(res.msg || "Something's wrong."))
    } else {
      return res
    }
  },
  (error) => {
    // 错误的请求结果处理，这里的代码根据后台的状态码来决定错误的输出信息
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          Message.error('error request')

          break

        case 401:
          Message.error('Unauthorized, please log in again.')
          router.replace({ path: '/401' })
          break

        case 403:
          Message.error('Access denied')

          break

        case 404:
          Message.error('Wrong request, the resource was not found.')
          router.replace({ path: '/404' })

          break

        case 405:
          Message.error('Request method not allowed')

          break

        case 408:
          Message.error('Request timeout')

          break

        case 500:
          Message.error('Server-side errors')

          break

        case 501:
          Message.error('Network not implemented')

          break

        case 502:
          Message.error('network error')

          break

        case 503:
          Message.error('Service unavailable')

          break

        case 504:
          Message.error('network timeout')

          break

        case 505:
          Message.error('The http version does not support this request')

          break

        default:
          Message.error(`connection error${error.response.status}`)
      }
    } else {
      Message.error('Failed to connect to server')
    }
    return Promise.reject(error)
  }
)

export default service
